.front-page {

    .home {
        &__title {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            font-family: $font-family--heading-home;
            font-weight: $font-weight-medium;
            font-size: 3.125rem;
            line-height: 3.438rem;
            text-transform: unset;
            padding: 0;
            margin: 0 auto 40px;

            & span {
                display: block;
                font-size: 1.563rem;
                line-height: 1.875rem;
                font-family: $font-family;
                font-weight: $font-weight-light;
                text-transform: uppercase;
                color: $color-main;
                margin-top: 10px;
                letter-spacing: 0.05em;
            }

            &:before {
                content: none;
            }
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    margin-top: 150px;
    transition: all 1s ease-in-out;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none
        }
    }

    .slideshow {
        position: relative;
        height: 580px;
        width: 100%;

        &:before, &:after {
            content: "";
            position: absolute;
            width: 2000px;
            right: 0;
            left: 0;
        }
    
        &:before {
            height: 90px;
            z-index: 3;
            bottom: 0;
            opacity: 1;
            background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/Vague.svg');
            background-size: cover;
            background-position-y: top;
            background-position-x: center;
        }
    
        &:after {
            height: 110px;
            z-index: 2;
            bottom: 0;
            opacity: .6;
            background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/Vague2.svg');
            background-size: cover;
            background-position-y: top;
            background-position-x: center;
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: 25px;
            bottom: 130px;
            width: fit-content;
            height: auto;
            background: $color-second;
            color: $color-text;
        }

        &__title {
            position: relative;
            font-size: 1.25rem;
            line-height: 1.25rem;
            font-family: $font-family;
            padding: 10px 20px 10px 5px;
            font-style: italic;
            font-weight: 400;
            max-width: 300px;
            margin: 0;

            &:before {
                content: "";
                position: absolute;
                height: 100%;
                width: 3000px;
                background-color: $color-second;
                top: 0;
                right: 100%;
            }
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 15px;
            bottom: 30px;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                margin: 0 10px 0 0;
                transition: .25s;
                background-color: $color-white;
                border: 1px solid $color-main;
                border-radius: $border-radius--round;
                pointer-events: auto;
                opacity: 1;
    
                &:hover {
                    border-color: $color-dark;
                }
    
                &-active {
                    border-color: $color-dark;
                }
            }
        }
    }
}


//======================================================================================================
// Access
//======================================================================================================
.access {

    &__section {
        margin-top: 40px;
        margin-bottom: 60px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        margin: 0 45px;
    }

    &__item {

        &__link {
            position: relative;
            display: inline-block;
            height: 155px;
            width: 130px;

            & svg {
                height:75px;
                width: 85px;
                margin-bottom: 20px;
            }

            &:before, &:after {
                content: "";
                position: absolute;
                left: 0;
                margin-left: 0;
                margin-right: auto;
                width: 40px;
                transition: all .5s ease-in-out;
            }

            &:before {
                bottom: 0;
                height: 1px;
                opacity: 1;
                background-color: $color-main;
            }

            &:after {
                bottom: -6px;
                height: 13px;
                opacity: 0;
                background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/ic-arrow.svg')
            }

            &:hover {
                &:before, &:after {
                    left: 90px;
                }

                &:before {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                }
            }
        }

        &__label {
            text-transform: uppercase;
            font-weight: $font-weight-semibold;
            line-height: 1.25rem;
            color: $color-text;

            & span {
                display: block;
                font-weight: $font-weight-light;
                line-height: 1.25rem;
            }
        }
    }
}


//======================================================================================================
// Events
//======================================================================================================
.events {
    &__section {
        padding: 80px 0;
        background-color: $color-second;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 440px;
        columns: 2;
    }

    &__content {

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-bg--image;
            background-position: center;
        }
    }

    &__info {
        &__date {
            display: inline-flex;
            align-items: center;
            padding: 0 0 0 25px;
            height: 40px;
            font-size: 1.375rem;
            font-family: $font-family--heading-home;
            font-weight: $font-weight-bold;
            font-style: italic;
            color: $color-text;

            &__month {
                text-transform: capitalize;
            }
        }

        &__title {
            position: relative;
            font-size: 1.375rem;
            line-height: 1.563rem;
            font-family: $font-family;
            font-weight: $font-weight-bold;
            color: $color-text;
            margin: 0 0 30px 0;

            &:before {
                content: "";
                position: absolute;
                height: 1px;
                width: 36px;
                left: 0;
                bottom: -16px;
                background-color: $color-white;
            }
        }

        &__details {
            display: flex;
            flex-direction: column;
            color: $color-text;
            
            & span {
                display: flex;
                align-items: center;
                font-style: italic;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                & svg {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &__read-more {
            position: absolute;
            height: 40px;
            width: 40px;

            & svg {
                width: 40px;
                height: 40px;
                background-color: $color-second;
                transition: all .5s ease-in-out;
            }
        }

        
    }

    .featured-events {
        position: relative;
        z-index: 0;

        &:hover {

            .events__info__read-more {
                & svg {
                    background-color: $color-main;
                }
            }
        }

        &--2 {
            margin: 0!important;
        }

        &--big {
            height: 440px;
            width: 470px;
            background-color: $color-third;
            margin-right: 30px;
            overflow: hidden;

            .events {
                &__content {
                    height: 270px;
                    width: 470px;
                    background-color: $color-gray;
                    transition: all .5s ease-in-out;

                    & img {
                        height: 270px;
                        object-fit: cover;
                        object-position: top;
                        transition: all .5s ease-in-out;
                    }
                }

                &__info {
                    padding: 30px 25px 0;

                    &__title {
                        margin: 0 0 30px;
                    }

                    &__details {
                        opacity: 0;
                        transition: all .5s ease-in-out;
                    }

                    &__read-more {
                        right: 0;
                        bottom: 0;
                    }
                }
            }

            &:hover {
                .events {
                    &__content {
                        height: 200px;

                        & img {
                            height: 200px;
                        }
                    }

                    &__info {
                        &__details {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &--small {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            height: 205px;
            width: 670px;
            margin-bottom: 30px;
            
            &:before, &:after {
                content: "";
                position: absolute;
                z-index: -1;
                height: 40px;
                top: 0;
                left: 270px;
                margin: 0 auto;
                transition: all .5s ease-in-out;
            }

            &:before {
                width: 400px;
                background-color: $color-third;
            }

            &:after {
                width: 0;
                opacity: 0;
                background-color: $color-main;
            }

            .events {

                &__content {
                    order: 0;
                    width: 270px;
                    height: 205px;

                    & img {
                        height: 205px;
                        object-fit: cover;
                        object-position: top;
                    }
                }

                &__info {
                    order: 2;
                    padding: 20px 10px 0 30px;
                    width: 400px;

                    &__title {
                        &:before {
                            background: $color-main;
                        }
                    }

                    &__date {
                        order: 1;
                    }

                    &__read-more {
                        left: 0;
                        bottom: 0;
                    }
                }
            }

            &:hover {
                &:after {
                    width: 400px;
                    opacity: 1;
                }
            }
        }
    }

    &__template {
        margin-top: 40px;
        text-align: right;

        & .button {
            width: 370px;
            margin: 0;

            &:first-child {
                margin-right: 30px;
                color: $color-white;
                background-color: $color-dark;
                border-color: $color-dark;

                & span {

                    &:before {
                        background-color: $color-white;
                    }
                    &:after {
                        background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/ic-arrow-white.svg')
                    }
                }
            }

            &:last-child {
                &:hover {
                    color: $color-dark;
                    background-color: $color-main;
                    border-color: $color-main;
                }
            }

            & span {
                position: relative;
                z-index: 0;
                padding-left: 50px;
                transition: all .5s ease-in-out;

                &:before, &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 40px;
                    transition: all .5s ease-in-out;
                }

                &:before {
                    left: 0;
                    height: 1px;
                    opacity: 1;
                    background-color: $color-dark;
                }

                &:after {
                    right: -45px;
                    height: 13px;
                    opacity: 0;
                    background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/ic-arrow.svg')
                }
            }
                
            &:hover {

                span {
                    padding-right: 50px;
                    padding-left: 0;

                    &:before {
                        opacity: 0;
                        left: -45px;
                    }

                    &::after {
                        right: 0;
                        opacity: 1;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Posts
//======================================================================================================
.post {
    &__section {
        padding: 80px 0;
        background-color: $color-dark;
    }

    &__title {
        color: $color-white;

        & span {
            color: $color-main;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;

        .featured-post {
            display: inline-block;
            width: 370px;

            &:hover {

                .post {

                    &__content {

                        &__read-more {
                            background-color: $color-main;
    
                            & svg {
                                fill: $color-dark;
                            }
                        }
                    }
                }
            }

            .post {
                &__content {
                    
                    &--notimg {
                        background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
                        background-position: center;
                    }
                }
            }
        }
    }

    &__info {
        color: $color-white;
        padding: 15px 0 0;

        &__taxo {
            position: relative;
            font-size: 1.125rem;
            line-height: 1.563rem;
            font-style: italic;
            font-weight: $font-weight-light;
            color: $color-white;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: -9px;
                height: 1px;
                width: 36px;
                background-color: $color-main;
            }
        }

        &__title {
            font-size: 1.375rem;
            line-height: 1.563rem;
            font-weight: $font-weight-bold;
            font-family: $font-family;
            color: $color-white;
            margin: 20px 0 10px;
        }

        &__introduction {
            font-size: $font-size--text;
            line-height: 1.25rem;
            font-weight: $font-weight-normal;
            color: $color-white;
        }
    }

    &__content {
        position: relative;
        width: 370px;
        height: 185px;

        &__read-more {
            position: absolute;
            width: 40px;
            height: 40px;
            left: 0;
            bottom: 0;
            background-color: $color-dark;
            transition: all $duration ease-in-out;

            & svg {
                width: 40px;
                height: 40px;
                fill: $color-white;
            }
        }
    }

    &__template {
        margin-top: 40px;
        text-align: right;

        & .button {
            width: 370px;
            margin: 0;
            
            &:last-child {
                &:hover {
                    color: $color-dark;
                    background-color: $color-main;
                    border-color: $color-main;
                }
            }

            & span {
                position: relative;
                z-index: 0;
                padding-left: 50px;
                transition: all .5s ease-in-out;

                &:before, &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 40px;
                    transition: all .5s ease-in-out;
                }

                &:before {
                    left: 0;
                    height: 1px;
                    opacity: 1;
                    background-color: $color-dark;
                }

                &:after {
                    right: -45px;
                    height: 13px;
                    opacity: 0;
                    background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/ic-arrow.svg')
                }
            }
                
            &:hover {

                span {
                    padding-right: 50px;
                    padding-left: 0;

                    &:before {
                        opacity: 0;
                        left: -45px;
                    }

                    &::after {
                        right: 0;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .alerte {

        &__container {
            height: 200px;
            margin-bottom: 30px;
        }

        &__item {
            position: relative;
            display: inline-flex;
            width: 100%;
            height: 200px;
            background-color: $color-second;

            &:hover {

                .alerte {
                    &__read-more {
                        background-color: $color-main;

                        & svg {
                            fill: $color-dark;
                        }
                    }
                }
            }
        }

        &__content {
            height: 200px;
            width: 370px;
            
            & img {
                height: 200px;
                width: 370px;
            }

            &--notimg {
                background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__info {
            padding: 35px 100px 40px 30px;

            &__label {
                position: relative;
                font-size: 1.563rem;
                line-height: 1.875rem;
                font-style: italic;
                font-weight: $font-weight-light;
                color: $color-text;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -9px;
                    height: 1px;
                    width: 36px;
                    background-color: $color-main;
                }
            }

            &__title {
                font-size: 1.375rem;
                line-height: 1.563rem;
                font-weight: $font-weight-bold;
                font-family: $font-family;
                color: $color-text;
                margin: 20px 0 10px;
            }

            &__introduction {
                font-size: $font-size--text;
                line-height: 1.25rem;
                font-weight: $font-weight-normal;
                color: $color-text;
            }
        }

        &__read-more {
            position: absolute;
            width: 40px;
            height: 40px;
            right: 0;
            bottom: 0;
            background-color: $color-dark;
            transition: all $duration ease-in-out;

            & svg {
                width: 40px;
                height: 40px;
                fill: $color-white;
            }
        }
    }
}



//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__container {
        display: flex;
        margin-top: 80px;

        .swiper-container {
            &--kiosque {
                width: 570px;
                height: 375px;
                margin-left: 0;
                margin-right: 30px;
            }

            &--thumbs {
                .swiper-slide {
                    cursor: pointer;
                }
            }
        }
    }

    &__title.home__title {
        align-items: flex-start;
        margin: 0 0 30px;
    }

    &__heading {
        width: 570px;

        .swiper-container {
            height: 250px;

            &--thumbs {
                .swiper-slide {
                    .kiosque {
                        &__thumb {
                            &__image {
                                position: relative;
                                z-index: 0;

                                & img {
                                    height: 250px;
                                    object-fit: cover;
                                }

                                &:before {
                                    content: "";
                                    position: absolute;
                                    z-index: 1;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    background-color: $color-bg--transparent;
                                    transition: all $duration ease-in-out;
                                }
                            }
                        }
                    }
                }
                .swiper-slide-active {
                    .kiosque {
                        &__thumb {
                            &__image {
                                &:before {
                                    background-color: rgba(0, 0, 0, 0.5);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__content {
        display: flex;
        width: 570px;
        height: 375px;
        background-color: $color-white;

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 300px;
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            padding-top: 15px;

            & .btn {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                margin-bottom: 15px;
                color: $color-dark;
                transition: all $duration ease-in-out;

                & svg {
                    width: 36px;
                    height: 36px;
                    fill: $color-dark;
                    border: 1px solid $color-main;
                    border-radius: $border-radius--round;
                    margin-right: 15px;
                    transition: all $duration ease-in-out;
                }

                &:hover {

                    & svg {
                        background-color: $color-main;
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }
        }

        &__title {
            display: flex;
            align-items: center;
            height: 110px;
            padding: 0 30px;
            margin: 0;
            font-size: 1.375rem;
            line-height: 1.563rem;
            font-family: $font-family;
            font-weight: $font-weight-bold;
            background-color: $color-second;
        }

        &__infos {
            padding-left: 30px;

            span {
                text-transform: uppercase;
            }
        }
    }

    &__image {
        width: 270px;

        & img {
            width: 270px;
            height: 375px;
            object-fit: cover;
        }
    }

    &__template {
        margin-top: 40px;
        text-align: right;

        .container--pagination {
            display: none;
        }

        &__button {
            width: 370px;
            margin: 0;
            
            &:last-child {
                &:hover {
                    color: $color-dark;
                    background-color: $color-main;
                    border-color: $color-main;
                }
            }

            & span {
                position: relative;
                z-index: 0;
                padding-left: 50px;
                transition: all .5s ease-in-out;

                &:before, &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 40px;
                    transition: all .5s ease-in-out;
                }

                &:before {
                    left: 0;
                    height: 1px;
                    opacity: 1;
                    background-color: $color-dark;
                }

                &:after {
                    right: -45px;
                    height: 13px;
                    opacity: 0;
                    background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/ic-arrow.svg')
                }
            }
                
            &:hover {

                span {
                    padding-right: 50px;
                    padding-left: 0;

                    &:before {
                        opacity: 0;
                        left: -45px;
                    }

                    &::after {
                        right: 0;
                        opacity: 1;
                    }
                }
            }
        }
    }
}



//======================================================================================================
// usefull links
//======================================================================================================
.usefull {

    &__section {
        margin: 40px 0;
    }

    &__title {
        &.home__title {
            margin: 0 0 40px;
            align-items: flex-start;
        }
    }

    &__container {
        & .swiper-container {
            height: 170px;
        }
    }

    &__item {
        border: 1px solid $color-main;
        box-sizing: border-box;
        overflow: hidden;
        transition: all $duration ease-in-out;

        & img {
            height: 168px;
            object-fit: contain;
            transform: scale(1);
            transition: all $duration ease-in-out;
        }

        &:hover {
            & img {
                transform: scale(1.1);
            }
        }
    }

    &__pagination {
        margin-top: 30px;

        & .swiper-pagination-bullet {
            width: 14px;
            height: 14px;
            border: $border-width $border-style $color-main;
            background-color: $color-bg--transparent;
            margin-right: 10px;
            opacity: 1;
            transition: all $duration ease-in-out;

            &-active, &:hover {
                border-color: $color-dark;
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    // ALU
    .home-slideshow {

        .slideshow {
            height: 465px;

            &:before, &:after {
                width: 100%;
            }

            &:before {
                height: 67px;
            }

            &:after {
                height: 82px;
            }

            &__content {
                bottom: 110px;
            }

            &__pagination {
                bottom: 20px;
            }
        }
    }

    // Access
    .access {

        &__list {
            margin: 0 5px;
        }
    }

    // Events
    .events {
        &__section {
            padding: 60px 0 80px;
        }

        &__container {
            height: 410px;
        }

        &__info__title {
            margin-bottom: 25px;
            
            &:before {
                bottom: -12px;
            }
        }

        .featured-events {

            &--big {
                width: 380px;
                height: 410px;
                margin-right: 20px;

                .events {
                    &__content {
                        width: 380px;
                        height: 250px;

                        & img {
                            height: 250px;
                        }
                    }
                }

                &:hover {

                    .events {
                        &__content {
                            height: 175px;

                            & img {
                                height: 175px;
                            }
                        }
                    }
                }
            }

            &--small {
                width: 540px;
                height: 195px;
                margin-bottom: 20px;

                &:before, &:after {
                    left: 220px;
                }
                
                &:before {
                    width: 320px;
                }

                .events {
                    &__content {
                        width: 220px;
                        height: 195px;

                        & img {
                            height: 195px;
                        }
                    }

                    &__info {
                        padding: 20px 0 0 20px;
                        width: 320px;
                    }
                }

                &:hover {
                    &:after {
                        width: 320px;
                    }
                }
            }
        }
    }

    // Post
    .post {

        &__section {
            padding: 60px 0 80px;
        }
        
        .alerte {

            &__container {
                margin-bottom: 20px;
            }

            &__content {
                img {
                    width: 300px;
                }
            }

            &__info {
                padding: 25px 60px 35px 20px;
            }
        }

        &__container {
            .featured-post {
                width: 300px;

                .post {
                    &__content {
                        width: 300px;
                        height: 150px;

                        img {
                            width: 300px;
                            height: 150px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }

    // Kiosque
    .kiosque {

        &__title {
            &.home__title {
                margin-bottom: 20px;

                span {
                    margin-top: 0;
                }
            }
        }

        &__container {
            .swiper-container {
                &--kiosque {
                    width: 460px;
                    height: 305px;
                }

                &__thumbs {
                    height: 200px;
                }
            }
        }

        &__content {
            width: 460px;
            height: 305px;

            &__container {
                width: 240px;
            }

            &__infos {
                padding-left: 20px;
            }

            &__title {
                padding: 0 20px;
                height: 90px;
            }

            &__buttons {
                padding-left: 20px;
                padding-top: 0;

                .btn {
                    margin-bottom: 10px;
                }
            }
        }

        &__image {
            width: 220px;
        
            img {
                width: 220px;
                height: 375px;
            }
        }

        &__heading {
            width: 460px;

            .swiper-container {
                &--thumbs {
                    height: 200px;
                    
                    .swiper-slide {
                        .kiosque {
                            &__thumb {
                                &__image {
                                    img {
                                        height: 200px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Usefull
    .usefull {

        &__container {
            .swiper-container {
                height: 140px;
            }
        }

        &__item {
            & img {
                height: 138px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    // ALU
    .home-slideshow {
        margin: 0;

        .slideshow {
            height: 375px;

            &:before {
                height: 58px;
            }

            &:after {
                height: 71px;
            }

            &__content {
                bottom: 85px;
            }

            &__pagination {
                bottom: 15px;
            }
        }
    }

    // Access
    .access {

        &__list {
            flex-wrap: wrap;
            width: 530px;
            margin: 0 45px;
        }

        &__item {
            margin-right: 70px;
            margin-bottom: 40px;

            &:nth-child(3), &:nth-child(6) {
                margin-right: 0;
            }

            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                margin-bottom: 0;
            }
        }
    }

    // Events
    .events {

        &__container {
            flex-direction: unset;
            height: fit-content;
        }

        .featured-events {
            &--big {
                display: flex;
                width: 100%;
                height: 200px;
                margin: 0 0 20px;

                .events {

                    &__content {
                        width: 300px;
                        height: 200px;

                        & img {
                            width: 300px;
                            height: 200px;
                        }
                    }

                    &__info {
                        width:320px;
                        padding: 10px 20px 0;
                        margin-top: 40px;

                        &__date {    
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 320px;
                            padding-left: 20px;
                        }

                        &__title {
                            font-size: 1.125rem;
                            line-height: 1.375rem;
                        }

                        &__details {
                            opacity: 1;
                        }
                    }
                }

                &:hover {
                    .events {
                        &__content {
                            height: 200px;

                            & img {
                                height: 200px;
                            }
                        }
                    }
                }
            }

            &--1 {
                margin: 0 20px 0 0;
            }

            &--small {
                height: auto;
                width: 300px;

                &:before {
                    width: 300px;
                    left: 0;
                    padding: 0 20px;
                }

                &:hover {
                    &:after {
                        width: 300px;
                        left: 0;
                        padding: 0 20px;
                    }
                }

                .events  {

                    &__content {
                        width: 300px;
                        height: 150px;

                        & img {
                            width: 300px;
                            height: 150px;
                        }
                    }

                    &__info {
                        width: 300px;
                        padding: 20px 0 0;

                        &__date {
                            width: 300px;
                            order: unset;
                            padding: 0;
                            justify-content: center;
                        }

                        &__title {
                            font-size: 1.125rem;
                            line-height: 1.375rem;
                        }

                        &__read-more {
                            bottom: unset;
                            top: 150px;
                        }
                    }
                }
            }
        }

        &__template {
            text-align: unset;
            display: flex;

            .button {
                width: 300px;
                padding: 10px;
                overflow: hidden;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    // Post
    .post {

        .alerte {
            &__container {
                height: 250px;
            }

            &__item {
                height: 250px;
            }

            &__content {
                width: 300px;
                height: 250px;

                & img {
                    width: 300px;
                    height: 250px;
                }
            }

            &__info {
                padding: 15px 20px 20px;
                width: 280px;
            }
        }
        
        &__container {
            flex-direction: column;

            .featured-post {
                display: flex;
                width: 100%;
                height: 180px;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                .post {
                    &__content {
                        width: 300px;
                        height: 180px;

                        & img {
                            width: 300px;
                            height: 180px;
                        }
                    }

                    &__info {
                        width: 300px;
                        padding: 0 0 0 20px;

                        &__title {
                            margin: 15px 0 10px;
                        }
                    }
                }
            }
        }

        &__template {
            .button {
                width: 300px;
                overflow: hidden;
            }
        }
    }

    // Kiosque
    .kiosque {

        .container {
            position: relative;
        }

        &__container {
            margin-top: 60px;
            flex-direction: column;

            .swiper-container {
                &--kiosque {
                    order: 1;
                    width: 100%;
                    height: 410px
                }
            }
        }

        &__content {
            width: 100%;
            height: 410px;

            &__container {
                width: 320px;
                height: 230px;
                margin-top: 45px;
            }

            &__buttons {
                flex-direction: unset;

                .btn {
                    margin: 0 10px 0 0;

                    svg {
                        margin: 0;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        &__image {
            width: 300px;
            height: 410px;

            & img {
                width: 300px;
                height: 410px;
            }
        }

        &__heading {
            .swiper-container {
                &--thumbs {
                    display: none;
                }
            }
        }

        &__template {
            position: absolute;
            z-index: 1;
            right: 10px;
            bottom: 0;
            margin: 0;

            .container--pagination {
                display: block;
                text-align: left;
                margin-bottom: 45px;

                .swiper-pagination-bullet {
                    width: 14px;
                    height: 14px;
                    border: 1px solid $color-main;
                    background-color: $color-bg--transparent;
                    margin-right: 10px;
                    opacity: 1;

                    &-active, &:hover {
                        border-color: $color-dark;
                    }
                }
            }

            &__button {
                width: 300px;
                margin: 0;
                padding: 10px 0;
                overflow: hidden;
            }
        }
    }

    // Usefull-link
    .usefull {

        &__container {
            .swiper-container {
                height: 190px;
            }
        }

        &__item {
            img {
                height: 188px;
                width: 300px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .front-page {

        .home {
            &__title {
                & span {
                    font-size: 1.375rem;
                }
            }
        }
    }

    // ALU
    .home-slideshow {

        .slideshow {
            height: 250px;

            &:before {
                height: 46px;
            }

            &:after {
                height: 57px;
            }

            &__content {
                bottom: 60px;
            }

            &__pagination {
                bottom: 0;
            }
        }
    }

    // Access
    .access {

        &__list {
            margin: 0;
            width: 300px;
        }

        &__item {

            &:nth-child(1), &:nth-child(3), &:nth-child(5) {
                margin-right: 40px;
                margin-bottom: 40px;
            }

            &:nth-child(2), &:nth-child(4), &:nth-child(6) {
                margin-right: 0;
                margin-bottom: 40px;
            }

            &:nth-child(5), &:nth-child(6) {
                margin-bottom: 0;
            }
        }
    }

    // Events
    .events {

        .featured-events {
            &--big {
                flex-direction: column;
                height: 425px;
                width: 300px;

                &:before {
                    width: 300px;
                }

                &:hover {
                    &:after {
                        width: 300px;
                    }
                }

                .events {
                    &__info {
                        order: 2;
                        width: 300px;
                        margin: 0;
                        padding-left: 20px;

                        &__date {
                            font-size: 1.25rem;
                            position: unset;
                            width: 300px;
                            order: 1;
                        }
                    }
                }
            }

            &--small {
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }
            }
        }

        &__template {
            flex-direction: column;

            .button {
                &:first-child {
                    margin: 0 0 20px;
                }
            }
        }
    }

    // Post
    .post {

        .alerte {
            &__container {
                height: fit-content;
            }

            &__item {
                flex-direction: column;
                height: fit-content;
            }
        }

        &__container {

            .featured-post {
                flex-direction: column;
                height: fit-content;

                .post {
                    &__info {
                        padding: 20px 0 0;
                    }
                }
            }
        }
    }

    // Kiosque
    .kiosque {

        &__container {
            .swiper-container {
                &--kiosque {
                    height: 660px;
                    margin-right: 0;
                }
            }
        }

        &__content {
            height: fit-content;
            flex-direction: column;

            &__container {
                width: 300px;
                margin-top: 20px;
            }

            &__buttons, &__infos {
                padding: 0;
            }
        }

        &__template {
            position: initial;
            margin-top: 30px;

            .container--pagination {
                margin-bottom: 25px;
            }
        }
    }

    // Usefull
    .usefull {
        &__section {
            margin-top: 60px;
        }
    }
}