.footer {
    position: relative;
    z-index: 0;
    background-color: $color-third;

    &:before, &:after {

        content: "";
        position: absolute;
        top: 0;
    }

    &:before {
        height: 150px;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 1;
        background-image: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/Vague-footer.svg');
    }

    &:after {
        width: calc(50% + 98px);
        background-image: url('/wp-content/themes/seine-port/assets/src/images/Carte.png');
        height: 535px;
        right: 0;
        z-index: 0;
        background-position-y: bottom;
    }

    &__container {
        position: relative;
        z-index: 5;
    }

    &__infos {
        display: flex;
        align-items: flex-end;
        padding: 110px 0 50px;

        &__logo {
            margin-bottom: 40px;

            & svg {
                width: 235px;
                height: 55px;
            }
        }
        
        &__coordonnees {
            width: 270px;
            margin-right: 30px;
        }

        &__title {
            font-size: 1.25rem;
            line-height: 1.563rem;
            font-weight: $font-weight-bold;
            margin: 0 0 15px;
        }

        &__text {
            margin: 0 0 15px;
            line-height: 1.25rem;
        }

        &__phone {
            color: $color-dark;
            font-weight: $font-weight-semibold;
            font-style: italic;
        }
        
        .button {
            margin: 60px 0 0;
            width: 225px;
            height: 40px;
            transition: all $duration ease-in-out;

            &:hover {
                background-color: $color-main;
                border-color: $color-main;
                color: $color-text;
            }
        }

        &__horaires {
            width: 220px;
            margin-right: 30px;
            margin-bottom: 40px;
        }

        &__rs {

            &__list {
                display: flex;
                height: 40px;
            }

            &__item {
                margin-right: 10px;
            }

            &__link {
                height: 40px;

                & svg {
                    width: 40px;
                    height: 40px;
                    border: $border-width $border-style $color-main;
                    border-radius: $border-radius--round;
                    transition: all $duration ease-in-out;
                }

                &:hover {
                    & svg {
                        background-color: $color-main;
                    }
                }
            }
        }

        &__labels {

            .footer__infos__title {
                margin: 0;
            }

            &__list {
                display: flex;
                height: 55px;
            }

            &__link {
                height: 55px;
            }

            &__item:first-child {
                margin-right: 10px;
            }
        }
    }

    &__menu {
        position: relative;
        z-index: 5;
        padding: 30px 0;
        background: $color-dark;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;
            padding: 0 20px;

            a {
                color: $color-white;
                opacity: 1;

                &:hover {
                        color: $color-second;
                        opacity: .5;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 21px;
                background: $color-white;
                transform: translateY(-50%);
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {

        &:before {
            height: 110px;
            background-position: top center;
            background-size: cover;
        }

        &::after {
            width: calc(50% + 245px);
            height: 580px;
        }

        &__infos {

            &__coordonnees {
                width: 250px;
                margin-right: 20px;
            }

            &__horaires {
                position: absolute;
                left: 10px;
                bottom: 120px;
                margin: 0;
            }

            & .button {
                margin: 160px 0 0;
            }

            &__rs {
                margin-bottom: 100px;
            }

            &__labels {
                margin-left: -200px;
            }
        }

        &__menu {
            & li {
                font-size: 0.938rem;
                padding: 0px 25px;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {

        &:before {
            height: 90px;
        }

        &:after {
            content: unset;
        }

        &__column {
            text-align: center;
        }

        &__infos {
            align-items: center;
            padding: 70px 0 45px;
            flex-direction: column;

            &__logo {
                margin-bottom: 30px;
                text-align: center;
            }

            &__coordonnees {
                width: 300px;
                margin-right: 0;
                text-align: center;
            }

            &__horaires {
                left: 0;
                right: 0;
                bottom: unset;
                top: 300px;
                margin: 0 auto;
                text-align: center;
                width: 250px;
            }

            &__rs {
                margin: 30px 0;
                text-align: center;
            }

            &__labels {
                margin: 0 auto;
                text-align: center;
            }
        }

        &__menu {
            padding: 10px 0;

            li {
                padding: 10px 25px;

                &:nth-child(3) {
                    &:before {
                        content: unset;
                    }
                }

                &:nth-child(4) {
                    padding-left: 0;
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .footer {

        &:before {
            height: 70px;
        }

        &__menu {
            li {
                &:nth-child(2), &:nth-child(3), &:nth-child(5) {
                    padding-left: 0;
                }

                &:nth-child(3) {
                    &:before {
                        content: "";
                    }
                }

                &:nth-child(4) {
                    padding-left: 25px;
                }
            }
        }
    }

}