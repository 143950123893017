body.a42-ac-dys-font #nav-main .nav-main-item .nav-main-link {
    font-size: 17px;
}

#nav-main {
    z-index: 10;
    padding: 0 290px 0 125px;
    pointer-events: none;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;
        width: 751px;
    } 

    .nav-main-item {
        pointer-events: auto;

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:first-child {
            .nav-main-link {
                padding-left: 0;
            }
        }

        &:nth-child(2) {
            margin-right: 177px;

            .nav-main-link {
                &:after {
                    left: 40px;
                }
            }
        }

        &:last-child {
            margin-left: 177px;

           .nav-main-link {
                padding-right: 0;

                &:after {
                    left: 40px;
                }
            }
        }

        &:hover {

            .nav-main-link {
                &:after {
                    background-color: $color-main;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 150px;
            padding: 0 40px;
            color: $color-text;
            text-transform: uppercase;
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: $font-weight-semibold;
            transition: all 1s ease-in-out;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: 40px;
                height: 1px;
                background: $color-bg--transparent;
                bottom: 55px;
                transition: all $duration ease-in-out;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            background: $color-second;
            box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.1);

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    padding: 15px 30px 15px 45px;
                    color: $color-text;
                    font-weight: $font-weight-medium;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        width: 35px;
                        height: 1px;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        opacity: 1;
                        margin: auto 0;
                        background: $color-text;
                        transition: all $duration ease-in-out;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 35px;
                        height: 12px;
                        top: 0;
                        left: -35px;
                        bottom: 0;
                        margin: auto 0;
                        opacity: 0;
                        background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/ic-arrow.svg');
                        background-size: contain;
                        transition: all $duration ease-in-out;
                    }

                    &:hover {
                        &:before {
                            opacity: 0;
                            left: 35px;
                        }
                        &::after {
                            left: 0;
                            opacity: 1;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    font-style: italic;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px 10px 10px;
    color: $color-dark;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        margin: auto 0;
        border: 1px solid $color-dark;
        border-radius: $border-radius--round;
    }

    .navicon {
        position: relative;
        display: block;
        height: 2px;
        top: 0;
        width: 20px;
        margin-right: 20px;
        background: $color-dark;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-dark;
            transition: all $duration ease-out;
        }

        &:before {
            top: 5px;
        }

        &:after {
            top: -5px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        padding: 0 240px 0 80px;

        .nav-main-item {
            &:nth-child(2) {
                margin-right: 150px;

                .nav-main-link {
                    &:after {
                        left: 20px;
                    }
                }
            }

            &:last-child {
                margin-left: 150px;

                .nav-main-link {
                    &:after {
                        left: 20px;
                    }
                }
            }

            .nav-main-link {
                padding: 0 20px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;
        padding: 0;
        pointer-events: auto;

        .container--nav {
            position: relative;
            height: 100%;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            background: $color-second;

            position: fixed;
            width: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            padding-top: 135px;
            overflow: scroll;
        }

        .nav-main-item {
            max-width: 640px;
            margin: 0 auto 40px!important;
            width: 100%;

            &:nth-child(2), &:last-child {
                margin: 0 auto;
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 40px;
                padding: 0;
                color: $color-text;
                text-transform: uppercase;

                &:first-child {
                    padding: 0 20px;
                }

                &:after {
                    bottom: 0;
                    left: 20px;
                    width: 57px;
                    background: $color-bg--transparent;
                }
            }

            .sub-menu {
                .nav-drop {
                    .nav-child-item, .nav-grandchild-item {
                        a {
                            padding: 15px 30px 15px 35px;

                            &:before {
                                width: 20px;
                                background: $color-main;
                            }

                            &:after {
                                content: unset;
                            }
                        }
                    }
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                box-shadow: unset;

                .nav-main-link {
                    color: $color-dark;

                    &:after {
                        bottom: 0;
                        background: $color-main;
                        width: 57px;
                        left: 20px;
                    }
                }

                .sub-menu {
                    display: flex;
                    position: initial;
                    box-shadow: unset;
                    padding: 10px;
                    padding: 5px 10px;

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-child-item a {
                        padding: 15px 30px 15px 35px;
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    position: fixed;
                    color: $color-dark;

                    &:before {
                        border-color: $color-dark
                    }

                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                            background-color: $color-dark;
                        }

                        &:after {
                            transform: rotate(45deg);
                            background-color: $color-dark;
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            z-index: 5;
            width: 100%;
            height: 75px;
            margin: 0;
            padding: 0 0 0 10px;
            font-size: 1.375rem;
            font-weight: $font-weight;
            text-transform: uppercase;
            pointer-events: auto;
        }
    }
    
}


// 640
@media screen and (max-width: $small) {

    #nav-main {
        height: 70px;
        margin-top: -70px;

        .menu__nav__list {
            padding-top: 100px;
        }

        .nav-main_icon {
            color: $color-white;

            &:before {
                border-color: $color-white;
            }

            & .navicon {
                background: $color-white;

                &:before, &:after {
                    background-color: $color-white;
                }
            }
        }

        .nav-main_button {

            &:checked {
                ~ .nav-main_icon {
                    z-index: 1;
                    color: $color-white;

                    &:after {
                        content: "";
                        position: fixed;
                        z-index: -1;
                        width: 100%;
                        height: 70px;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        background-color: $color-dark;
                    }
        
                    &:before {
                        border-color: $color-white;
                    }
        
                    & .navicon {
        
                        &:before, &:after {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }

        .nav-main-item {
            max-width: 320px;
            margin: 0 auto 40px!important;
            width: 100%;
        }
    }
}
