.breadcrumb {

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-dark;
        padding: 0 30px 0 0;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 5px;
            bottom: 0;
            margin: auto 0;
            width: 20px;
            height: 20px;
            transform: rotate(-90deg);
            background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/chevron-right.svg');
            background-position: center;
        }

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        &:last-child {
            &:after {
                content: none;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
