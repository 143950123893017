header {
    position: relative;
    background-color: $color-second;
    
    position: fixed;
    z-index: 99;
    width: 100%;
}

.header-top {
    .container--identity {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 150px;
        transition: all 1s ease-in-out;

        .site-identity {
            position: absolute;
            left: 0;
            right: 0;
            width: 310px;
            margin: 0 auto;
            transition: all 1s ease-in-out;
        }

        .identity {
            &__title {
                position: relative;
                width: 310px;
                height: 150px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                font-size: 0;
                line-height: 0;
                margin: 0;
                padding: 20px 0;
                background-color: $color-white;
                overflow: hidden;
                transition: all 1s ease-in-out;

                &__logo {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 20px;
                    margin: 0 auto;
                    height: 105px;
                    opacity: 1;
                    transition: all .5s ease-in-out;

                    animation-name: reverseLogoOpacity;
                    animation-duration: 3s;
                    animation-delay: 0;
                }

                &__blason {
                    position: absolute;
                    left: 0px;
                    right: 0;
                    top: 20px;
                    margin: 0 auto;
                    height: 60px;
                    opacity: 0;
                    transition: all .5s ease-in-out;
                    
                    animation-name: reverseBlasonOpacity;
                    animation-duration: 1s;
                    animation-delay: 500ms;
                }

                &:hover {
                    cursor: pointer;
                }

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 2;
                    width: 40px;
                    height: 2px;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    background-color: $color-dark;

                    animation-name: afterLogoMask;
                    animation-duration: 1.5s;
                }

                &.active {
                    .identity__title {
                        &__logo {
                            animation-name: logoOpacity;
                            animation-duration: 1s;
                            animation-delay: 0s;
                        }

                        &__blason {
                            animation-name: blasonOpacity;
                            animation-duration: 1s;
                            animation-delay: 0s;
                        }
                    }

                    &:before {
                        animation-name: beforeLogoMask;
                        animation-duration: 1.5s;
                    }
                }

                @keyframes logoOpacity {
                    0% {
                        opacity: 1;
                    }

                    33% {
                        opacity: 1;
                    }

                    66% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 0;
                    }
                }

                @keyframes blasonOpacity {
                    0% {
                        opacity: 0;
                    }

                    33% {
                        opacity: 0;
                    }

                    66% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                @keyframes reverseLogoOpacity {
                    0% {
                        opacity: 0;
                    }

                    33% {
                        opacity: 0;
                    }

                    66% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                @keyframes reverseBlasonOpacity {
                    0% {
                        opacity: 1;
                    }

                    33% {
                        opacity: 1;
                    }

                    66% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 0;
                    }
                }

                @keyframes beforeLogoMask {
                    0% {
                        height: 2px;
                        width: 40px;
                        background-color: $color-dark;
                    }

                    10% {
                        height: 2px;
                        width: 310px;
                        background-color: $color-main;
                    }

                    25% {
                        height: 150px;
                        width: 310px;
                    }

                    75% {
                        height: 150px;
                        width: 310px;
                    }

                    90% {
                        height: 2px;
                        width: 310px;
                        background-color: $color-main;
                    }
                    
                    100% {
                        height: 2px;
                        width: 40px;
                        background-color: $color-dark;
                    }
                }

                @keyframes afterLogoMask {
                    0% {
                        height: 2px;
                        width: 40px;
                        background-color: $color-dark;
                    }

                    10% {
                        height: 2px;
                        width: 310px;
                        background-color: $color-main;
                    }

                    25% {
                        height: 150px;
                        width: 310px;
                    }

                    75% {
                        height: 150px;
                        width: 310px;
                    }

                    90% {
                        height: 2px;
                        width: 310px;
                        background-color: $color-main;
                    }
                    
                    100% {
                        height: 2px;
                        width: 40px;
                        background-color: $color-dark;
                    }
                }
            }
        }
    }
}


// Header Fixed
.header.header--fixed {

    .header-top {
        .container {
            &--identity {
                height: 100px;

                .identity {
                    &__title {
                        height: 100px;

                        &__logo {
                            opacity: 0
                        }

                        &__blason {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .tools {
        &__content {
            height: 100px;
        }
    }

    #nav-main {
        .nav-main-item {
            .nav-main-link {
                height: 100px;

                &:after {
                    bottom: 30px;
                }
            }
        }
    }

    & + main {
        .wrapper {
            .front-page {
                .home-slideshow {
                    margin-top: 100px;
                }
            }

            .cover {
                margin-top: 100px;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {

    .container--tools {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &__content {
        position: absolute;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 0;
        width: calc(100% - 30px);
        height: 150px;
        margin: 0;
        background: $color-bg--transparent;
        transition: all 1s ease-in-out;

        & > svg {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-text; 
        }
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        background-color: $color-bg--transparent;
        border: 0;

        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: $font-weight-semibold;
        
        svg {
            fill: $color-text;
            transition: all $duration;
        }

        &:hover {
            cursor: pointer;

            svg {
                background-color: $color-dark;
                fill: $color-second;
            }

            span {
                color: $color-text;
            }
        }
    }
    &__accessibility, &__search {
        svg {
            width: 40px;
            height: 40px;
            border: 1px solid $color-dark;
            border-radius: $border-radius--round;
        }
    }

    &__search {
        margin-right: 20px;

        svg {
            margin-right: 15px;
        }

        span {
            color: $color-text;
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }
}

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header-top {
        .container {
            &--identity {
                .site-identity {
                    width: 250px;
                }

                .identity {
                    &__title {
                        width: 250px;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    header {
        position: initial;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
    
    .tools {
        .container {
            &--tools {
                justify-content: flex-end;
            }
        }

        &__content {
            width: auto;
        }

        &__search {

            &.tool {
                margin-left: 20px;
            }

            svg {
                margin: 0;
            }

            span {
                display: none;
            }
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        .container {
            &--identity {
                height: 120px;

                .site-identity {
                    width: 200px;
                    height: 120px;
                }

                .identity {
                    &__title {
                        width: 200px;
                        height: 120px;
                        padding: 10px 0;

                        &__logo {
                            height: 84px;
                        }
                    }
                }
            }
        }
    }

    .tools {
        height: 70px;
        background-color: $color-dark;

        &__content {
            height: 70px;
        }

        .tool {
            & svg {
                fill: $color-white;
                border-color: $color-white;
            }
        }
    }
}
