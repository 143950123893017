.cover {
    position: relative;
    margin-top: 150px;
    transition: all 1s ease-in-out;
    overflow: hidden;

    &__image-wrapper {
        position: relative;
        width: 100%;
        height: 580px;
        background: $color-bg--image;

        &:before, &:after {
            content: "";
            position: absolute;
            width: 2000px;
            right: 0;
            left: 0;
        }
    
        &:before {
            height: 90px;
            z-index: 3;
            bottom: 0;
            opacity: 1;
            background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/Vague.svg');
            background-size: cover;
            background-position-y: top;
            background-position-x: center;
        }
    
        &:after {
            height: 110px;
            z-index: 2;
            bottom: 0;
            opacity: .6;
            background: url('/wp-content/themes/seine-port/assets/src/images/sprite-images/Pictos/Vague2.svg');
            background-size: cover;
            background-position-y: top;
            background-position-x: center;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-white;
        padding: 30px 0;
    }

    &__title {
        color: $color-dark;
        font-family: $font-family--heading-home;
        font-weight: $font-weight-medium;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    
    .cover {
        &__image-wrapper {
            height: 380px;

            &:before, &:after {
                width: 100%;
            }

            &:before {
                height: 67px;
            }

            &:after {
                height: 82px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        margin-top: 0;
        
        &__image-wrapper {
            height: 375px;

            &:before {
                height: 58px;
            }

            &:after {
                height: 71px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        margin: 0;
        
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 250px;

            &:before {
                height: 46px;
            }

            &:after {
                height: 57px;
            }
        }
    }

}
