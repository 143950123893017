.usefulPage{
  form{
    margin-bottom: 0;
  }
  &__wrapper{
    margin: 30px 0 0;
    background: $color-second;
    padding: 25px 20px;
    border-radius: $border-radius;
  }
  &__fields,
  &__answered{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4{
      margin: 0;
    }
  }
  &__choices {
    display: flex;

    &.done{
      .usefulPage__answer{
        label{
          cursor: auto;
          pointer-events: none;
        }
      }
      &[data-answer="true"]{
        .usefulPage__answer{
          &:not(.answerNo) {
            label {
              background: $color-dark;
              border-color: $color-dark;
              color:$color-white;
              svg{
                fill:$color-white;
              }
            }
          }
          &.answerNo{
            label {
              background: $color-white;
              border-color: $color-white;
              color:$color-gray;
              opacity: 0.5;
              svg{
                fill:$color-gray;
              }
            }
          }
        }
      }
      &[data-answer="false"]{
        .usefulPage__answer{
          &:not(.answerNo) {
            label {
              background: $color-white;
              border-color: $color-white;
              color:$color-gray;
              opacity: 0.5;
              svg{
                fill:$color-gray;
              }
            }
          }
          &.answerNo{
            label {
              background: $color-dark;
              border-color: $color-dark;
              color:$color-white;
              svg{
                fill:$color-white;
              }
            }
          }
        }
      }
    }
  }
  &__answer{
    &:first-child{
      label{
        margin-right: 20px;
      }
    }
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $btn-spe-font-size;
      font-weight: $font-weight-bold;
      line-height: $btn-spe-line-height;
      padding: $btn-spe-padding;
      border-radius: $btn-spe-border-radius;
      margin:0;
      background: $color-dark;
      border: $btn-border-width $btn-border-style $color-dark;
      color: $color-white;
      text-transform: $btn-text-transform;
      text-decoration: none;
      text-align: center;
      cursor:pointer;
      transition: all $duration ease;

      svg {
        fill: $color-white;
      }

      &:hover,
      &:focus,
      &:active,
      &.is-active,
      &.is-checked {
        background: $color-bg--transparent;
        color: $color-dark;

        svg {
          fill: $color-dark;
        }
      }

      svg {
        width: 13px;
        height: 13px;
        margin: 0 10px 0 0;
      }
    }

    input{
      display: none;
    }
  }
  &__message{
    textarea{
      background: $color-white;
    }
  }
  &__mention{
    margin: 5px 0;
    a{
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }

}


// 960
@media screen and (max-width: $small) {
  .usefulPage {
    &__answered,
    &__fields {
      align-items: baseline;
      flex-direction: column;
      > .acf-label,
      > p{
        margin-bottom: 10px;
      }
    }
  }
}
